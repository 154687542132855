import React from "react";
import styles from "../styles/Article.module.css";
import ButtonConsult from "./buttonConsult";

export default function Article() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          <span className={styles.title_line}>
            インフラ運用を自動化することで
          </span>
          <span className={styles.title_line}>
            日本のイノベーションを加速する
          </span>
        </h2>
        <p className={styles.first}>
          事業者が顧客や従業員にインターネットサービスを提供するには、インフラなどのシステムが必要です。しかし、システムの維持にはさまざまな問題があり、事業者が「本業」に専念しにくい状況になっています。
        </p>
        <ul className={styles.items}>
          <li className={styles.item}>
            <div className={styles.item_visual + " " + styles.image1}></div>
            <div className={styles.item_detail}>
              <h3 className={styles.item_title}>維持に消えていく6兆円</h3>
              <p className={styles.item_text}>
                システムの維持には多くのコストがかかります。日本国内で投入されるIT予算の総額は、8兆円前後と見られます。その内訳、新規システムへの投資と既存システムの維持の比率は2:8で、約6.4兆円にものぼる予算が維持だけにに当てられています
                <sup>1</sup>。
              </p>
              <p className={styles.item_text}>
                世界の新規と既存の比率は5:5前後<sup>2</sup>
                なので、これと比べると、日本はイノベーションに手が回っていないことが分かります。現場レベルでも「保守で手一杯」という状況を多く見かけますから、この統計は実態にも如実に反映されていると考えられます。
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual + " " + styles.image2}></div>
            <div className={styles.item_detail}>
              <h3 className={styles.item_title}>2025年の崖</h3>
              <p className={styles.item_text}>
                国内のIT産業は、イノベーションを起こしにくい予算構造になっています。そのため、時代に合わせたビジネスモデルを作りにくくなり、このままいくと2025年には崖を転がり落ちるように競争力を失い、その損失額は年間12兆円にのぼると考えられています。
              </p>
              <p className={styles.item_text}>
                システムの維持に仕向けられている予算を、イノベーションに振り向けられるようになることは、IT産業の目下の課題と言えます。
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <div>
              <h3 className={styles.item_title}>
                ベンダーがやるべきだったこと
              </h3>
              <p className={styles.item_text}>
                システム維持に費やす費用がなかなか減らないのは、インフラ運用サービスを提供するベンダー側にも2つの問題があります。
              </p>
              <p className={styles.item_text}>
                ひとつは、ベンダーが顧客のクラウド化を十分にリードできていない点です。国内企業のクラウド利用率は年平均5%で増えてはいますが、2019年の統計ではまだ約36%の企業がクラウドを利用していません
                <sup>3</sup>
                。世界に目を向けると、企業のクラウドの普及率は94%ですから
                <sup>4</sup>
                、日本は出遅れているのが実情です。クラウド化することで顧客の運用負荷軽減が見込めますので、顧客のことを考えるとベンダーはもっと早くからクラウド化に大きく舵を切るべきでした。
              </p>
              <p className={styles.item_text}>
                もうひとつの問題は、費用の高い人海戦術型の運用スタイルを続けている点です。業界最大手のベンダーでは、インフラ運用事業の売上のうち60%が人的コストで、多くの人員を要するビジネスモデルになっています。クラウドインフラでは、プログラムで保守するのが容易になっているのに、依然として人中心の運用スタイルです。顧客を想うと、クラウド化と同時に運用自動化にもチャレンジすべきでしたが、高い人件費を顧客に転嫁するモデルが未だに主流なのです。
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual + " " + styles.image3}></div>
            <div className={styles.item_detail}>
              <h3 className={styles.item_title}>イノベーションを加速する</h3>
              <p className={styles.item_text}>
                IT予算の8割がシステム維持に消えていき、2025年の崖に警鐘が鳴らされる中、国内のインフラ業界はクラウド化を推進できず、人的コストを顧客に転嫁する旧来の運用スタイルを変えていません。
              </p>
              <p className={styles.item_text}>
                私たちがインフラ事業を起こしたのは、こうした現状を打破したいと考えたからです。インフラ運用の多くをプログラムが行う「運用自動化」を推し進めることで、顧客のIT予算に占める維持費を低減し、その分イノベーションが加速する社会を作りたいと考えています。
              </p>
            </div>
          </li>
          <li className={styles.item}>
            <div>
              <h3 className={styles.item_title}>クリエティビティに集中する</h3>
              <p className={styles.item_text}>
                インフラ運用の実務に目を向けると、多くの運用手順が定形作業であることに気づきます。たとえば、アラートの発生時に確認すべき項目や、一次対応の手順、問題が起こったときに真っ先に誰に通知をするかなどは、事前に決まっていて人がその手順とルールに基づいて規律的に運用を行います。
              </p>
              <p className={styles.item_text}>
                こういった予め決まっているルーチンワークは、人間よりもプログラムが得意な領域です。定形作業を自動化することで、そこにかかっていた人件費を大幅に削減できます。加えて、定形作業に割り当てられていた人員はそこから開放されるので、クリエイティブな仕事に従事できるようになります。今まで浪費されてきた貴重な才能が、重要な仕事に集中投資できるようなるので、事業価値の積み重ねが加速することが期待できるわけです。
              </p>
            </div>
          </li>
        </ul>
        <p className={styles.last}>
          今や車の自動運転が注目され、宇宙ロケットが自動着陸する時代です。自動化が当たり前になってくる時流に合わせて、私たちクラフトマンソフトウェアは時代にそぐわなくなったインフラ運用を見直し、その自動化を推し進めることで、日本のイノベーションを加速していきます。
        </p>
        <ol className={styles.caption}>
          <li className={styles.caption_item}>
            <a href="https://it.impress.co.jp/articles/-/18036" target="_blank">
              「IT予算の8割がシステム維持管理」が依然続き、沈みゆく日本のITユーザー/IT業界
              | IT Leaders
            </a>
          </li>
          <li className={styles.caption_item}>
            <a
              href="https://www2.deloitte.com/jp/ja/pages/technology/articles/tsa/cio-survey.html"
              target="_blank"
            >
              2018 グローバル CIO
              サーベイ｜テクノロジーストラテジー&amp;アーキテクチャー｜デロイト
              トーマツ グループ｜Deloitte
            </a>
          </li>
          <li className={styles.caption_item}>
            <a
              href="https://www.soumu.go.jp/johotsusintokei/whitepaper/ja/r02/html/nd252140.html"
              target="_blank"
            >
              総務省｜令和2年版
              情報通信白書｜企業におけるクラウドサービスの利用動向
            </a>
          </li>
          <li className={styles.caption_item}>
            <a
              href="https://resources.flexera.com/web/media/documents/rightscale-2019-state-of-the-cloud-report-from-flexera.pdf"
              target="_blank"
            >
              RightScale 2019 State of the Cloud Report from Flexera
            </a>
          </li>
        </ol>
      </div>
      <div className={styles.button}>
        <ButtonConsult />
      </div>
    </div>
  );
}
