import React from "react";
import styles from "../styles/Problem.module.css";

export default function Problem() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner + " " + styles.problem}>
        <h2 className={styles.title}>こんなお悩みありませんか？</h2>
        <ul className={styles.items}>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img src="/illust1.png" alt="サービスは絶対に止めたくない！" />
            </div>
            <p className={styles.item_title}>サービスは絶対に止めたくない！</p>
            <p className={styles.item_text}>
              サービスが止まれば、<strong>機会損失や信頼問題</strong>
              につながってしまいます。社内向けのサービスであれば、社員の業務を止めてしまうこともあります。だから、サービスは絶対に止めたくない。インフラは安定稼働が至上命題です。しかし…
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img src="/illust2.png" alt="強固なインフラを作るのが難しい" />
            </div>
            <p className={styles.item_title}>強固なインフラを作るのが難しい</p>
            <p className={styles.item_text}>
              しかし、止まらないインフラを作るのは簡単ではありません。安定稼働するインフラを作るには、
              <strong>あらゆる障害局面を熟知している必要</strong>
              があります。ところが、社内だけでは経験値が不足していたり、24時間365日の運用体制を敷くための人員確保ができなかったりと、理想と現実のギャップに悩まされます。
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img src="/illust3.png" alt="セキュリティが心配で夜も眠れない…" />
            </div>
            <p className={styles.item_title}>
              セキュリティが心配で夜も眠れない…
            </p>
            <p className={styles.item_text}>
              サービスはオープンしてからが本番です。運用開始後もっとも気になることはセキュリティです。もしも社外秘や個人情報が漏えいしたらと考えると不安です。
              <strong>これまで無事でも、今後も大丈夫とは限りません</strong>
              。今この瞬間、そして、これからもセキュリティが本当に万全なのか心配になります。これでは夜も眠れません。
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
