import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./styles/globals.css";
import "./styles/animate.min.css";
import Header from "./components/header";
import Hero from "./components/hero";
import PoweredBy from "./components/poweredBy";
import Problem from "./components/problem";
import Solution from "./components/solution";
import Service from "./components/service";
import Step from "./components/step";
import Case from "./components/case";
import Security from "./components/security";
import Engineer from "./components/engineer";
import Article from "./components/article";
import Faq from "./components/faq";
import Contact from "./components/contact";
import Overview from "./components/overview";
import Footer from "./components/footer";
import Sticky from "./components/sticky";

function Home() {
  const companyWebsiteTitle = "Craftsman Software | あなたのインフラ部門に";
  const companyWebsiteUrl = "https://craftsman-software.com";
  const companyDescription =
    "インフラ運用を自動化し、手作業を限りなくゼロする会社。"; // TODO: DatoCMSのSEO設定からデータを取得する

  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 1) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  });
  return (
    <div>
      <Helmet>
        <title>{companyWebsiteTitle}</title>
        <meta name="description" content={companyDescription} />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href="/apple-touch-icon-180x180.png"
        />
        <link rel="icon" type="image/png" href="/icon-192x192.png" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <script
          type="module"
          src="https://unpkg.com/ionicons@5.2.3/dist/ionicons/ionicons.esm.js"
        ></script>
        <script src="https://embed.typeform.com/embed.js"></script>
        <meta property="og:title" content={companyWebsiteTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={companyDescription} />
        <meta property="og:url" content={companyWebsiteUrl} />
        <meta property="og:site_name" content="Craftsman Software" />
        <meta
          property="og:image"
          content="https://craftsman-software.com/ogp.png"
        />
        <meta property="og:locale" content="ja_JP" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://craftsman-software.com/ogp.png"
        />
        <meta name="twitter:title" content={companyWebsiteTitle} />
        <meta name="twitter:description" content={companyDescription} />
      </Helmet>
      <Header isScrolled={isScrolled} />
      <main>
        <Hero />
        <PoweredBy />
        <Problem />
        <Solution />
        <Service />
        <Step />
        <Case />
        <Security />
        <Engineer />
        <Article />
        <Faq />
        <Contact />
        <Overview />
      </main>
      <Footer isTopPage />
      <Sticky isScrolled={isScrolled} />
    </div>
  );
}

export default Home;
