import * as React from "react";
import styles from "../styles/Hamburger.module.css";

type Props = {
  isScrolled: boolean;
  isNavOpen: boolean;
  toggleNav: () => void;
};

const hamburger: React.FC<Props> = (props) => {
  if (props.isNavOpen) {
    return (
      <button
        className={styles.is_opened + " " + styles.hamburger}
        onClick={props.toggleNav}
      >
        <span className={styles.is_opened + " " + styles.hamburger_icon}></span>
        <span className={styles.is_opened + " " + styles.hamburger_icon}></span>
      </button>
    );
  } else if (props.isScrolled) {
    return (
      <button
        className={styles.is_scrolled + " " + styles.hamburger}
        onClick={props.toggleNav}
      >
        <span
          className={styles.is_scrolled + " " + styles.hamburger_icon}
        ></span>
        <span
          className={styles.is_scrolled + " " + styles.hamburger_icon}
        ></span>
      </button>
    );
  }

  return (
    <button className={styles.hamburger} onClick={props.toggleNav}>
      <span className={styles.hamburger_icon}></span>
      <span className={styles.hamburger_icon}></span>
    </button>
  );
};
export default hamburger;
