import * as React from "react";
import styles from "../styles/HeaderLogo.module.css";

type Props = {
  isScrolled: boolean;
  isNavOpen: boolean;
};

const headerLogo: React.FC<Props> = (props) => {
  return (
    <p
      className={
        (props.isScrolled ? styles.is_scrolled + " " : "") +
        (props.isNavOpen ? styles.is_opened + " " : "") +
        styles.logo
      }
    >
      <a href="/">
        <img src="/logo.svg" alt="Craftsman Software" />
      </a>
    </p>
  );
};
export default headerLogo;
