import React from "react";
import styles from "../styles/Contact.module.css";
import Typeform from "./typeform";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

export default function Contact() {
  return (
    <div className={styles.wrap} id="contact">
      <div className={styles.inner}>
        <h2 className={styles.title}>
          <span className={styles.title_line}>どんなことでもお気軽に</span>
          <span className={styles.title_line}>ご相談ください！</span>
        </h2>
        <p className={styles.button}>
          <Typeform>
            <span className={styles.button_icon}>
              <ion-icon name="mail-outline"></ion-icon>
            </span>
            <span className={styles.button_text}>お問い合わせ</span>
          </Typeform>
        </p>
      </div>
    </div>
  );
}
