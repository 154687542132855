import React from "react";
import styles from "../styles/PoweredBy.module.css";

export default function PoweredBy() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>Powered by</h2>
        <ul className={styles.logos}>
          <li className={styles.logo}>
            <img src="/aws.png" alt="AWS" />
          </li>
          <li className={styles.logo}>
            <img src="/kubernetes.png" alt="kubernetes" />
          </li>
          <li className={styles.logo}>
            <img src="/terraform.svg" alt="terraform" />
          </li>
        </ul>
      </div>
    </div>
  );
}
