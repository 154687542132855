import React from "react";

const Typeform: React.FC = ({ children }) => {
  return (
    <a
      className="typeform-share button"
      href="https://form.typeform.com/to/WnF9U9gN?typeform-medium=embed-snippet"
      data-mode="popup"
      data-size="100"
      target="_blank"
    >
      {children}
    </a>
  );
};

export default Typeform;
