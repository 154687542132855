import React from "react";
import styles from "../styles/Service.module.css";
import ButtonConsult from "./buttonConsult";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

export default function Service() {
  return (
    <div className={styles.wrap} id="service">
      <div className={styles.inner}>
        <h2 className={styles.title}>サービス内容</h2>
        <p className={styles.subtitle}>インフラおまかせパック</p>
        <ul className={styles.items}>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="pie-chart-outline"></ion-icon>
              </span>
              現状把握・分析ヒアリング
            </p>
            <p className={styles.item_text}>
              アプリケーションがどのような構成で動作しているかを詳細にヒアリングし、インフラ構成の設計と運用設計をご提案します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="globe-outline"></ion-icon>
              </span>
              試験環境構築
            </p>
            <p className={styles.item_text}>
              試験環境でアプリケーションがすべて動作していることを確認してインフラ構成を確認できます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="globe-outline"></ion-icon>
              </span>
              本番環境構築
            </p>
            <p className={styles.item_text}>
              試験環境で得られたフィードバックを参考にし、本番環境とそれに伴うステージング環境、テスト環境も構築します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="cash-outline"></ion-icon>
              </span>
              コスト削減
            </p>
            <p className={styles.item_text}>
              夜間や土日など使わない時間帯のサービスを自動停止、自動開始しコストを削減します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="shield-checkmark-outline"></ion-icon>
              </span>
              セキュリティ不備を自動修復
            </p>
            <p className={styles.item_text}>
              重大なセキュリティの設定不備を常に自動でプログラムが監視し、問題が発見されたら通知します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="analytics-outline"></ion-icon>
              </span>
              グラフで状況把握
            </p>
            <p className={styles.item_text}>
              インフラ環境を監視する設定を行い状況を視認できるように各種状況をグラフで確認できます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="bar-chart-outline"></ion-icon>
              </span>
              ログ収集と可視化
            </p>
            <p className={styles.item_text}>
              アプリケーションやOSのログを集約し可視化を行います。ログの検索、グラフの確認がウェブから行えます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="notifications-outline"></ion-icon>
              </span>
              重要なエラーの通知
            </p>
            <p className={styles.item_text}>
              アプリケーションやOSで発生した重要なエラーを即座にSlackや電話で通知します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="eye-outline"></ion-icon>
              </span>
              外部からの監視と計測
            </p>
            <p className={styles.item_text}>
              お客様の環境をエンドユーザ様と同様にインターネットから接続し、外部から見た応答速度を常に監視します。サイトが遅くなっていたり接続しにくい状況になったら修復します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="bandage-outline"></ion-icon>
              </span>
              障害を自動で復旧
            </p>
            <p className={styles.item_text}>
              収集している計測値やログなどから障害を検知し、プログラムが自動で復旧します。プログラムが復旧できなくてもエンジニアが24時間365日対応します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="flask-outline"></ion-icon>
              </span>
              バックアップ復元試験
            </p>
            <p className={styles.item_text}>
              定期的に取得しているバックアップが正常に動作するかプログラムが自動でバックアップから復元しアプリケーションのテストを行います。もしバックアップが壊れていたら通知されます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="stats-chart-outline"></ion-icon>
              </span>
              インフラの活動を可視化（提供予定）
            </p>
            <p className={styles.item_text}>
              インフラで発生する様々なアクティビティはダッシュボードで可視化され、いつ誰がどのようなことを行ったか、どういう障害や問題が発生したかなどがひと目で確認できます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="document-text-outline"></ion-icon>
              </span>
              インフラ通知表
            </p>
            <p className={styles.item_text}>
              インフラで何が起きたか、コストはどうだったのか、順調に運用されているか、などは月間のインフラ通知表で要約して俯瞰的に確認することができます。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="trending-up-outline"></ion-icon>
              </span>
              継続的な改善
            </p>
            <p className={styles.item_text}>
              インフラ通知表や対話のなかで課題となっていることを毎月確認し、アプリケーションやビジネスの成長の足並みに合わせてインフラも継続的に改善します。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>
                <ion-icon name="chatbubbles-outline"></ion-icon>
              </span>
              迅速なコミュニケーション
            </p>
            <p className={styles.item_text}>
              Slackなどのチャットツールでリアルタイムにインフラチームとコミュニケーションが取れます。
            </p>
          </li>
        </ul>
        <p className={styles.customize}>
          <span className={styles.customize_line}>お客様のご要望に応じて</span>
          <span className={styles.customize_line}>
            カスタマイズも可能です！
          </span>
        </p>
      </div>
      <div className={styles.button}>
        <ButtonConsult />
      </div>
    </div>
  );
}
