import React from "react";
import { Link } from "react-scroll";
import { useState } from "react";
import styles from "../styles/Header.module.css";
import HeaderLogo from "./headerLogo";
import Hamburger from "./hamburger";
import Typeform from "./typeform";
import { Link as CrossPageLink } from "gatsby";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

type Props = {
  isScrolled: boolean;
};

const header: React.FC<Props> = (props) => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const closeNav = () => {
    setIsNavOpen(false);
  };
  return (
    <header
      className={
        styles.header + " " + (isNavOpen ? " " + styles.is_opened : "")
      }
    >
      <div
        className={
          isNavOpen
            ? styles.is_opened + " " + styles.header_in
            : styles.header_in
        }
      >
        <HeaderLogo isNavOpen={isNavOpen} isScrolled={props.isScrolled} />
        <Hamburger
          isNavOpen={isNavOpen}
          isScrolled={props.isScrolled}
          toggleNav={toggleNav}
        />
        <nav
          className={
            (isNavOpen ? styles.is_opened + " " + styles.nav : styles.nav) +
            (props.isScrolled ? " " + styles.is_scrolled : "")
          }
        >
          <ul className={styles.menu}>
            <li className={styles.menu_item}>
              <Link to="service" smooth onClick={closeNav}>
                サービス内容
              </Link>
            </li>
            <li className={styles.menu_item}>
              <Link to="case" smooth onClick={closeNav}>
                ケーススタディ
              </Link>
            </li>
            <li className={styles.menu_item}>
              <Link to="engineer" smooth onClick={closeNav}>
                トップエンジニア紹介
              </Link>
            </li>
            <li className={styles.menu_item}>
              <Link to="faq" smooth onClick={closeNav}>
                Q&amp;A
              </Link>
            </li>
            <li className={styles.menu_item}>
              <Link to="overview" smooth onClick={closeNav}>
                会社概要
              </Link>
            </li>
            <li className={styles.menu_item}>
              <CrossPageLink to="/recruit">
                採用情報
              </CrossPageLink>
            </li>
          </ul>
          <ul className={styles.contact}>
            <li className={styles.contact_item}>
              <Typeform>
                <span className={styles.contact_icon}>
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                お問い合わせ
              </Typeform>
            </li>
            <li className={styles.contact_item + " " + styles.tel}>
              <p className={styles.tel_title}>導入のご相談はお気軽に！</p>
              <a href="tel:0354221075">
                <span className={styles.contact_icon}>
                  <ion-icon name="call-outline"></ion-icon>
                </span>
                03-5422-1075
              </a>
              <p className={styles.tel_date}>平日: 9:00-18:00</p>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
export default header;
