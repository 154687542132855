import React from "react";
import styles from "../styles/Solution.module.css";
import ButtonConsult from "./buttonConsult";

export default function Solution() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner + " " + styles.solution}>
        <h2 className={styles.title}>
          <span className={styles.title_line}>こんなインフラの心配は、</span>
          <span className={styles.title_line}>もうおしまい！</span>
        </h2>
        <ul className={styles.items}>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img src="/illust4.png" alt="サービスを止めない5つの工夫" />
            </div>
            <p className={styles.item_title}>サービスを止めない5つの工夫</p>
            <ol className={styles.ordered}>
              <li className={styles.ordered_item}>
                AWSとKubernetesで、技術に裏打ちされた
                <strong>止まりにくいインフラを設計</strong>。
              </li>
              <li className={styles.ordered_item}>
                プログラムによる24時間監視で、もしサービスが止まっても
                <strong>すぐに自動復旧</strong>。
              </li>
              <li className={styles.ordered_item}>
                人とプログラムが障害の予兆を察知。
                <strong>先回りして障害をブロック</strong>。
              </li>
              <li className={styles.ordered_item}>
                自動復旧プログラムが対応できないイレギュラーは、
                <strong>プロフェッショナルがカバー</strong>。
              </li>
              <li className={styles.ordered_item}>
                インフラの障害復旧試験を行うことにより、障害が確実に復旧できるかを
                <strong>定期的にチェック</strong>。
              </li>
            </ol>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img src="/illust5.png" alt="あなたのインフラ部門に" />
            </div>
            <p className={styles.item_title}>あなたのインフラ部門に</p>
            <p className={styles.item_text}>
              インフラの専門知識がなくても大丈夫！これまで、経営層や事業オーナーのような非エンジニアの方々と二人三脚で問題に向き合ってきた弊社だからこそ、漠然とした心配ごとから要件を発見し、お客様にとって
              <strong>ベストなインフラと運用をご提案</strong>します。
            </p>
          </li>
          <li className={styles.item}>
            <div className={styles.item_visual}>
              <img
                src="/illust6.png"
                alt="セキュリティの底上げ、迅速対応、可視化"
              />
            </div>
            <p className={styles.item_title}>
              セキュリティの底上げ、迅速対応、可視化
            </p>
            <p className={styles.item_text}>
              長年の知見から、セキュリティ問題を起こしにくい構成でインフラを構築します。また、セキュリティ上の問題を検知した際には、プログラムや専門家が迅速に対処します。セキュリティ上の
              <strong>問題の有無が一目瞭然な「インフラ通知表」</strong>
              も提供します。
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.button}>
        <ButtonConsult />
      </div>
    </div>
  );
}
