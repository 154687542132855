import React from "react";
import styles from "../styles/Overview.module.css";

export default function Overview() {
  return (
    <div className={styles.wrap} id="overview">
      <div className={styles.inner}>
        <h2 className={styles.title}>会社概要</h2>
        <div className={styles.overview}>
          <dl className={styles.items}>
            <div className={styles.item}>
              <dt className={styles.item_title}>会社名</dt>
              <dt className={styles.item_detail}>
                <p>株式会社クラフトマンソフトウェア</p>
                <p>Craftsman Software, Inc.</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>役員</dt>
              <dt className={styles.item_detail}>
                <p>森 怜峰 (代表取締役)</p>
                <p>野澤 秀仁 (取締役)</p>
                <p>竹原 俊広 (取締役)</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>本社</dt>
              <dt className={styles.item_detail}>
                <p>〒175-0092</p>
                <p>東京都板橋区赤塚8-10-2-701</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>神田オフィス</dt>
              <dt className={styles.item_detail}>
                <p>〒101-0041</p>
                <p>東京都千代田区神田須田町1-24-4</p>
                <p>THE CROSS神田 2階</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>代表電話番号</dt>
              <dt className={styles.item_detail}>
                <p>03-5422-1075 (平日: 9:00〜18:00)</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>設立</dt>
              <dt className={styles.item_detail}>
                <p>2013年10月15日</p>
              </dt>
            </div>
          </dl>
          <dl className={styles.items}>
            <div className={styles.item}>
              <dt className={styles.item_title}>事業内容</dt>
              <dt className={styles.item_detail}>
                <ul>
                  <li>ソフトウェアテストの自動化サービスの提供</li>
                  <li>ソフトウェア開発のコンサルティングサービスの提供</li>
                  <li>ソフトウェア開発、システム構築、運用</li>
                  <li>システムインフラの設計、構築、監視、運用及びそれらの自動化</li>
                </ul>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>資本金</dt>
              <dt className={styles.item_detail}>
                <p>23,006,940円(準備金含む)</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>認証</dt>
              <dt className={styles.item_detail}>
                <p>ISMS (IS735461 / ISO27001)(本社)</p>
                <p>Pマーク (17004154)</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>
                主要取引先
                <p className={styles.terms}>(順不同、敬称略)</p>
              </dt>
              <dt className={styles.item_detail}>
                <p>本田技研工業株式会社</p>
                <p>株式会社NTTドコモ</p>
                <p>株式会社リクルートキャリア</p>
                <p>株式会社セプテーニ・オリジナル</p>
                <p>株式会社アッカ・インターナショナル</p>
              </dt>
            </div>
            <div className={styles.item}>
              <dt className={styles.item_title}>
                主要パートナー
                <p className={styles.terms}>(順不同、敬称略)</p>
              </dt>
              <dt className={styles.item_detail}>
                <p>エイチスリーパートナーズ有限会社</p>
                <p>株式会社すきまポイント</p>
              </dt>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
