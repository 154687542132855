import React from "react";
import styles from "../styles/Step.module.css";
import ButtonConsult from "./buttonConsult";
import TagFree from "./tagFree";

export default function Step() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>サービス開始までの5ステップ</h2>
        <ol className={styles.items}>
          <li className={styles.item}>
            <p className={styles.item_title}>お問い合わせ</p>
            <p className={styles.item_text}>
              お問い合わせいただいた後、ヒアリングに向けてのご案内をいたします。
            </p>
            <dl className={styles.period}>
              <dt className={styles.period_title}>期間：</dt>
              <dd className={styles.period_detail}>1〜2営業日以内にご連絡</dd>
            </dl>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              ヒアリング
              <TagFree />
            </p>
            <p className={styles.item_text}>
              お客様の現状の課題をお聞きします。
            </p>
            <dl className={styles.period}>
              <dt className={styles.period_title}>期間：</dt>
              <dd className={styles.period_detail}>5営業日程度</dd>
            </dl>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              ご提案
              <TagFree />
            </p>
            <p className={styles.item_text}>
              お客様の課題と費用感にフィットしたサービス内容とプランをご提案します。
            </p>
            <dl className={styles.period}>
              <dt className={styles.period_title}>期間：</dt>
              <dd className={styles.period_detail}>5営業日程度</dd>
            </dl>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>ご契約</p>
            <p className={styles.item_text}>
              ご契約後、すぐに詳細なヒアリングや設計を開始し、サービス開始準備に取り掛かります。
            </p>
            <dl className={styles.period}>
              <dt className={styles.period_title}>期間：</dt>
              <dd className={styles.period_detail}>5営業日程度</dd>
            </dl>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>サービス開始</p>
            <p className={styles.item_text}>
              設計や構築、運用設計や監視設定等を実施します。試験が完了したら運用を開始します。
            </p>
            <dl className={styles.period}>
              <dt className={styles.period_title}>期間：</dt>
              <dd className={styles.period_detail}>
                お客様の環境規模やご要望の複雑さによります
              </dd>
            </dl>
          </li>
        </ol>
      </div>
      <div className={styles.button}>
        <ButtonConsult />
      </div>
    </div>
  );
}
