import * as React from "react";
import styles from "../styles/Sticky.module.css";
import Typeform from "./typeform";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

type Props = {
  isScrolled: boolean;
};

const sticky: React.FC<Props> = (props) => {
  return (
    <div className={"animate__animated animate__fadeIn"}>
      <p
        className={
          styles.button + (props.isScrolled ? " " + styles.is_scrolled : "")
        }
      >
        <Typeform>
          <span className={styles.button_icon}>
            <ion-icon name="mail-outline"></ion-icon>
          </span>
          <span className={styles.button_text}>お問い合わせ</span>
        </Typeform>
      </p>
    </div>
  );
};
export default sticky;
