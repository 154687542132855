import React from "react";
import styles from "../styles/Engineer.module.css";

export default function Engineer() {
  return (
    <div className={styles.wrap} id="engineer">
      <div className={styles.inner}>
        <h2 className={styles.title}>トップエンジニア紹介</h2>
        <p className={styles.subtitle}>経験に裏打ちされた実力</p>
        <div className={styles.visual}></div>
        <ul className={styles.items}>
          <li className={styles.item}>
            <h3 className={styles.item_title}>Reo Mori</h3>
            <p className={styles.item_text}>
              17歳でホワイトハッカーを始め、企業向けセミナーを通じてクラッキングからネットワークを守るノウハウの伝搬に務める。
            </p>
            <p className={styles.item_text}>
              株式会社サーバイス、株式会社スイフトスタッフのCTOを歴任しながら、SNSやスマート照明、入退場管理システム、Facebookページマーケティングシステムなど、さまざまな業域の開発チーフを手掛ける。
            </p>
            <p className={styles.item_text}>
              2013年には、株式会社クラフトマンソフトウェアを創業し、ブラウザテスト自動化サービス「ShouldBee」でOpen
              NetworkLab DemoDay 2014 Spring最優秀チーム賞を受賞。
            </p>
            <p className={styles.item_text}>
              森の強みは、インフラとアプリケーションの2領域で、高い技術力を持つこと。アプリケーションと調和するインフラ設計や運用プロセスを考え出すことが得意。開発と運用のチームワークが円滑になるインフラの設計や、地方創生のための地方自治体向けプライベートクラウドの構築などを手掛けてきた。
            </p>
            <h4 className={styles.item_subtitle}>著作</h4>
            <dl className={styles.book}>
              <dt className={styles.book_visual}>
                <img
                  src="/book1.jpg"
                  alt="「特集２　軽量仮想化基盤のＤｏｃｋｅｒ　今ハマる三つの使い道: 開発と本番の環境がそろう」『日経ＳＹＳＴＥＭＳ2015年3月号』日経BP社"
                />
              </dt>
              <dd className={styles.book_detail}>
                「特集２　軽量仮想化基盤のＤｏｃｋｅｒ　今ハマる三つの使い道:
                開発と本番の環境がそろう」『日経ＳＹＳＴＥＭＳ2015年3月号』日経BP社
              </dd>
            </dl>
            <ul className={styles.other}>
              <li className={styles.other_item}>
                『実践ドメイン駆動設計』翔泳社, 邦訳レビュー
              </li>
              <li className={styles.other_item}>
                『すごい開発チーム育成ハンドブック』共著
              </li>
              <li className={styles.other_item}>
                『マンガでわかるDocker ①
                〜概念・基本コマンド編〜』湊川あい著, 監修
              </li>
            </ul>
            <h4 className={styles.item_subtitle}>講師・公演</h4>
            <ul className={styles.other}>
              <li className={styles.other_item}>「Vagrant入門」Schoo, 講師</li>
              <li className={styles.other_item}>
                「YYPHPの現場」PHPの現場, ゲスト出演
              </li>
            </ul>
          </li>
          <li className={styles.item}>
            <h3 className={styles.item_title}>Toshihiro Takehara</h3>
            <p className={styles.item_text}>
              1973年ラオスに生まれ、幼少期から日本で育つ。ペット業界での起業を経験後、2003年にIT業界に転向。大手企業のITインフラ運用管理の責任者を務める。
            </p>
            <p className={styles.item_text}>
              2013年には、インシデント管理システム「OTRS」の日本OTRSユーザ会の会長に就任。日本マイクロソフトや日本IBMとパートナーシップを組み、OTRSのセミナーを多数開催。OTRSの国内普及に寄与する。
            </p>
            <p className={styles.item_text}>
              ラオシステムズ代表、株式会社OSS
              ONE副社長、株式会社クラウドエイジア代表等を歴任し、2016年株式会社クラフトマンソフトウェアに取締役に就任。
            </p>
            <p className={styles.item_text}>
              運用設計とセキュリティが強み。運用領域では、通信業・製造業・流通業・サービス業などのべ100社以上の企業に対しインシデント管理を指南。セキュリティ領域では、大手金融機関のセキュリティ認証PCIDSSの運用を担当した。
            </p>
            <h4 className={styles.item_subtitle}>著作</h4>
            <dl className={styles.book}>
              <dt className={styles.book_visual}>
                <img
                  src="/book2.jpg"
                  alt="「Docker／Ansible／シェルスクリプト: 無理なくはじめるInfrastructure as Code」『Software Design 2014年11月号』技術評論社"
                />
              </dt>
              <dd className={styles.book_detail}>
                「Docker／Ansible／シェルスクリプト:
                無理なくはじめるInfrastructure as Code」『Software Design
                2014年11月号』技術評論社
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
  );
}
