import React from "react";
import styles from "../styles/Faq.module.css";

export default function Faq() {
  return (
    <div className={styles.wrap} id="faq">
      <div className={styles.inner}>
        <h2 className={styles.title}>よくあるご質問</h2>
        <ul className={styles.items}>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>Q</span>
              どんなときに電話してもらえますか？
            </p>
            <p className={styles.item_text}>
              あらかじめ要件を決めておき、それに従って電話連絡します。ただし、よくある事象についてはできるだけこちら側で対応を完了させ、お客様には報告の連絡だけになるよう心がけています。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>Q</span>
              アプリケーションのログも監視できますか？
            </p>
            <p className={styles.item_text}>
              もちろんできます。検知すべき文字列を一緒に決めて頂き、その文字列が検知されたら定形の処理をさせることもできますし、Slack連携や電話連絡することも可能です。
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.item_title}>
              <span className={styles.item_icon}>Q</span>
              お盆やお正月でも対応してもらえますか？
            </p>
            <p className={styles.item_text}>
              ご安心ください。監視で発生するアラートの対応は年中無休で24時間対応しています。
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
