import React from "react";
import styles from "../styles/Security.module.css";
import ButtonConsult from "./buttonConsult";

export default function Security() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>安心の3つの資格</h2>
        <p className={styles.subtitle}>
          <span className={styles.subtitle_line}>
            安心してお任せいただけるよう、
          </span>
          <span className={styles.subtitle_line}>
            弊社では情報管理やセキュリティの資格を取得しています。
          </span>
        </p>
        <ul className={styles.items}>
          <li className={styles.item}>
            {/*<p className={styles.item_visual}>*/}
            {/*  <img src="/pmark.png" alt="プライバシーマーク" />*/}
            {/*</p>*/}
            <h3 className={styles.item_title}>プライバシーマーク</h3>
            <p className={styles.item_text}>
              プライバシーマークは、日本産業規格「JIS Q
              15001個人情報保護マネジメントシステム－要求事項」に適合して、個人情報について適切な保護措置を講ずる体制を整備しているかを評価する第三者認証制度です。
            </p>
          </li>
          <li className={styles.item}>
            {/*<p className={styles.item_visual}>*/}
            {/*  <img src="/isms.png" alt="ISMS" />*/}
            {/*</p>*/}
            <h3 className={styles.item_title}>ISMS</h3>
            <p className={styles.item_text}>
              ISMS（情報セキュリティマネジメントシステム）とは、個人情報を含めた情報資産のセキュリティを管理する枠組みです。国際規格ISO/IEC
              27001や日本工業規格 JIS Q
              27001で定められた事項への遵守が評価される第三者認証制度です。
            </p>
          </li>
          <li className={styles.item}>
            {/*<p className={styles.item_visual}>*/}
            {/*  <img src="/ciw.png" alt="CIWセキュリティアナリスト" />*/}
            {/*</p>*/}
            <h3 className={styles.item_title}>CIWセキュリティアナリスト</h3>
            <p className={styles.item_text}>
              CIWは米国Certification
              Partner社によって運営されているインターネット技術者の技術者認定資格です。CIW
              Security
              Analystは、サーバーやネットワークのセキュリティに熟達した者に与えられる高難易度の資格です。弊社にはこの資格の保持者が在籍しています。
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.button}>
        <ButtonConsult />
      </div>
    </div>
  );
}
