import React from "react";
import styles from "../styles/Hero.module.css";
import Typeform from "./typeform";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "ion-icon": any;
    }
  }
}

export default function Hero() {
  return (
    <div className={styles.hero}>
      <div className={"animate__animated animate__fadeIn animate__fast"}>
        <h1 className={styles.copy}>
          <span className={styles.copy_line}>インフラの心配は、</span>
          <span className={styles.copy_line}>もうおしまい。</span>
        </h1>
      </div>
      <div
        className={
          "animate__animated animate__fadeIn animate__fast animate__delay-1s"
        }
      >
        <p className={styles.text}>
          <span className={styles.text_line}>みなさんは本業に専念。</span>
          <span className={styles.text_line}>インフラは専門家におまかせ。</span>
        </p>
      </div>
      <p
        className={
          "animate__animated animate__fadeIn animate__fast animate__delay-2s " +
          styles.button
        }
      >
        <Typeform>
          <span className={styles.button_icon}>
            <ion-icon name="mail-outline"></ion-icon>
          </span>
          <span className={styles.button_text}>お問い合わせ</span>
        </Typeform>
      </p>
    </div>
  );
}
