import React from "react";
import styles from "../styles/Case.module.css";

export default function Case() {
  return (
    <div className={styles.wrap} id="case">
      <div className={styles.inner}>
        <h2 className={styles.title}>ケーススタディ</h2>
        <ul className={styles.items}>
          <li className={styles.item}>
            <p className={styles.item_subtitle}>CASE STUDY #1</p>
            <h3 className={styles.item_title}>
              2年間、障害ゼロのEコマースを実現
            </h3>
            <div className={styles.item_visual}>
              <img src="/case1.jpg" alt="2年間、障害ゼロのEコマースを実現" />
            </div>
            <p className={styles.item_text}>
              Eコマースはシビアです。もしもシステムが止まると商品の購入や発送ができず、ビジネスに大打撃を与えます。インフラの安定性は売上に直結してきます。そのため、お客様からは
              <strong>「24時間、絶対に止めたくない」</strong>
              という強いご要望がありました。また、
              <strong>低予算で抑えたい</strong>とのご希望もありました。
            </p>
            <p className={styles.item_text}>
              難しい要望でしたが、弊社は3つの工夫で解決しました。1つ目は
              <strong>クラウド化</strong>
              です。本システムは、もともと社内設備で動いており、年末商戦に合わせた設備拡大や、予期せぬ故障への対応をクイックに行えませんでした。これを解決すべく、迅速に柔軟な対応ができるAWS基盤を整えました。
            </p>
            <p className={styles.item_text}>
              2つ目の施策は、もしもの障害に備えた
              <strong>Kubernetes(クバネティス)の採用</strong>
              です。これはGoogleのインフラを支える技術として知られています。Kubernetesには、
              人間が真似できない速さで障害を検知し
              、自己回復する機能があります。
            </p>
            <p className={styles.item_text}>
              3つ目の工夫は、<strong>補完型の有人運用</strong>
              です。Kubernetesによる自動運用は強力ですが、機械まかせにできない部分もあります。そこは技術者がカバーする体制を整えました。「人機一体」の運用体制により、高い運用能力を備えつつ、
              <strong>人件費を極力おさえることに成功</strong>しました。
            </p>
            <p className={styles.item_text}>
              これらの工夫により、本システムは
              <strong>2年間、一度もサービスが止まることなく運用</strong>
              されました。その間には、AWSそのものの障害もありました。こうした基幹障害も織り込んで設計していたため、問題なく乗り切ることができました。
            </p>
          </li>
          {/*<li className={styles.item}>*/}
          {/*  <p className={styles.item_subtitle}>CASE STUDY #2</p>*/}
          {/*  <h3 className={styles.item_title}>*/}
          {/*    2年間、障害ゼロのEコマースを実現*/}
          {/*  </h3>*/}
          {/*  <div className={styles.item_visual}>*/}
          {/*    <img src="/case2.jpg" alt="2年間、障害ゼロのEコマースを実現" />*/}
          {/*  </div>*/}
          {/*  <p className={styles.item_text}>*/}
          {/*    Eコマースはシビアです。もしもシステムが止まると商品の購入や発送ができず、ビジネスに大打撃を与えます。インフラの安定性は売上に直結してきます。そのため、お客様からは*/}
          {/*    <strong>「24時間、絶対に止めたくない」</strong>*/}
          {/*    という強いご要望がありました。また、低予算で抑えたいとのご希望もありました。*/}
          {/*  </p>*/}
          {/*  <p className={styles.item_text}>*/}
          {/*    難しい要望でしたが、弊社は3つの工夫で解決しました。1つ目はクラウド化です。本システムは、もともと社内設備で動いており、年末商戦に合わせた設備拡大や、予期せぬ故障への対応をクイックに行えませんでした。これ解決すべく、迅速に柔軟な対応ができるAWS基盤を整えました。*/}
          {/*  </p>*/}
          {/*  <p className={styles.item_text}>*/}
          {/*    2つ目の施策は、もしもの障害に備えたKubernetes(クバネティス)の採用です。これはGoogleのインフラを支える技術として知られています。Kubernetesには、*/}
          {/*    <strong>人間が真似できない速さで障害を検知し</strong>*/}
          {/*    、自己回復する機能があります。*/}
          {/*  </p>*/}
          {/*  <p className={styles.item_text}>*/}
          {/*    3つ目の工夫は、補完型の有人運用です。Kubernetesによる自動運用は強力ですが、機械まかせにできない部分もあります。そこは技術者がカバーする体制を整えました。「人機一体」の運用体制により、高い運用能力を備えつつ、人件費を極力おさえることに成功しました。*/}
          {/*  </p>*/}
          {/*  <p className={styles.item_text}>*/}
          {/*    これらの工夫により、本システムは2年間、一度もサービスが止まることなく運用されました。その間には、AWSそのものの障害もありました。こうした基幹障害も織り込んで設計していたため、問題なく乗り切ることができました。*/}
          {/*  </p>*/}
          {/*</li>*/}
        </ul>
      </div>
    </div>
  );
}
