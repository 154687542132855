import React from "react";
import styles from "../styles/ButtonConsult.module.css";
import Typeform from "./typeform";

export default function ButtonConsult() {
  return (
    <p className={styles.contact}>
      <Typeform>インフラのお悩みを無料で相談する</Typeform>
    </p>
  );
}
